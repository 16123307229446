import React from 'react';

import './style.css'

const Services = (props) => {

    return(
        <div className="service-style-1 section-padding">
            <div className="container">
                <div className="col-12">
                    <div className="section-title-s2 text-center">
                        <span>What We Do</span>
                        <h2>Our Services</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="service-wrap">
                            <div className="service-icon">
                                <i className="fi flaticon-turbo"></i>
                            </div>
                            <div className="service-text">
                                <h2>Engine Repair</h2>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4  col-md-6">
                        <div className="service-wrap">
                            <div className="service-icon-2">
                                <i className="fi flaticon-tyre"></i>
                            </div>
                            <div className="service-text">
                                <h2>Tires Replacement</h2>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4  col-md-6">
                        <div className="service-wrap">
                            <div className="service-icon-3">
                                <i className="fi flaticon-car-1"></i>
                            </div>
                            <div className="service-text">
                                <h2>Transmission</h2>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4  col-md-6">
                        <div className="service-wrap">
                            <div className="service-icon-4">
                                <i className="fi flaticon-car-repair"></i>
                            </div>
                            <div className="service-text">
                                <h2>Diagnostic</h2>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4  col-md-6">
                        <div className="service-wrap">
                            <div className="service-icon-5">
                                <i className="fi flaticon-battery"></i>
                            </div>
                            <div className="service-text">
                                <h2>Batteries</h2>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4  col-md-6">
                        <div className="service-wrap">
                            <div className="service-icon-6">
                                <i className="fi flaticon-electricity"></i>
                            </div>
                            <div className="service-text">
                                <h2>Breaks</h2>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default Services;
